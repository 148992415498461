@import "../../styles/index.scss";

header {
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 10px 25px -10px #502db20d;
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #fff;

    @include breakpoint("md-max") {
        padding: 16px 0;
    }

    .header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .desktop-login{
            @include breakpoint("md-max") {
                display: none;
            }
        }
        .mobile-menu {
            display: none;

            @include breakpoint("md-max") {
                padding-right: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 40px;
            }

            i {
                color: #e01a33;
                font-size: 50px;

            }
        }

        .menu {
            display: flex;
            align-items: center;
            gap: 40px;
            

            @include breakpoint("md-max") {
                display: none;
            }

            .active {
                color: #e01a33;

                &::before {
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    content: " ";
                    color: transparent;
                    background: #e01a33;
                    height: 2px;
                    width: 100%;
                    transition: 0.4s ease-in-out;
                }

            }

            a {
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: #222;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                position: relative;

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }

                &::after {
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    content: " ";
                    color: transparent;
                    background: #e01a33;
                    height: 2px;
                    width: 0;
                    transition: 0.4s ease-in-out;
                }

                &:hover {
                    color: #e01a33;
                }
            }
        }

        .logo {
            display: flex;
            align-items: center;
            gap: 14px;
            cursor: pointer;

            img {
                cursor: pointer;
                max-width: 140px;
                @include breakpoint("md-max") {
                    max-width: 200px;
                }
            }

            span {
                color: #e01a33;
                display: block;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
            }
        }

        .profile {
            background-color: #ed3237;
            border-radius: 7px;

            button {
                color: white !important;
                padding: 10px 0px;  
            }

        }


        .login-btn {
            background-color: #ed3237;
            color: white;
            padding: 7px 20px;
            border-radius: 10px;
            font-weight: 600;
            display: block;
            transition: all .3S ease-in-out;
            border: 1px solid #ed3237;
            @include breakpoint("md-max") {
                font-size: 30px;
            }
            &:hover {
                background-color: transparent;
                border: 1px solid #ed3237;
            }
        }
    }

}

.option-Btn {
    color: black !important;
    display: inline;
    transition: all .3S ease-in-out;
    font-weight: 600;

    &.active {
        color: white;
        border-radius: 10px;
        display: inline;
        transition: all .3S ease-in-out;
        font-weight: 600;
    }

    &:hover {
        color: #ed3237;
    }

}

.mobile-header {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    left: 0;

    &.hide {
        transform: translateX(100%);
        transition: .3s ease-in-out;
    }

    &.show {
        transform: translateX(0%);
        transition: .3s ease-in-out;
    }

    .header-sm {
        padding: 20px;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 60px;

        i {
            font-size: 20px;
            color: #e01a33;
            cursor: pointer;
            font-size: 40px;
        }

        .logo {
            display: flex;
            align-items: center;
            gap: 14px;
            cursor: pointer;

            img {
                cursor: pointer;
                max-width: 140px;
                @include breakpoint("md-max") {
                    max-width: 200px;
                }
            }

            span {
                color: #e01a33;
                display: block;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
            }
        }
    }

    .header-body {
        padding: 40px 20px;

        .active {
            color: #e01a33;

        }

        a {
            display: block;
            font-size: 18px;
            padding: 0 0 40px 0;
            font-weight: 600;
            color: #323232;
            text-align: center;
            @include breakpoint("md-max") {
                font-size: 30px;
            }
        }
    }
}