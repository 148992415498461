@import "../../../styles/index.scss";

.hero-banner-design {
  background-image: url("../../../assets/bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-attachment: fixed;
  height: calc(100vh - 81px);
  position: relative;
  display: flex;
  align-items: center;
  background-position: center;

  @include breakpoint("md-max") {
    height: 800px;
    padding: 80px;
    letter-spacing: 5px !important;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.6);
    content: "";
  }

  .w-full {
    width: 100%;
    position: relative;

    .hero-jummar-img {
      @include breakpoint("md-max") {
        display: none;
      }
    }

    h1 {
      font-size: 51px;
      color: #fff;
      margin: 0 0 24px 0;
      max-width: 650px;

      @include breakpoint("md-max") {
        font-size: 40px;
        line-height: 45px;
      }
    }

    p {
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      opacity: 0.8;
      margin: 0 0 24px 0;
      max-width: 650px;

      @include breakpoint("md-max") {
        font-size: 24px;
      }
    }
  }

  .button-outline {
    padding: 20px;

    a {
      @include breakpoint("md-max") {
        background: #ed3237;
        font-size: 18px;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}

.section-title {
  padding: 50px 0 20px 0;
  font-size: 20px;
  position: relative;
}

.slick-dots {
  @include breakpoint("sm-min") {
    display: none !important;
  }
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 15px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .35;
  color: #eb6363 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint("md-max") {
    display: none;
  }
}

.section-title::before {
  content: "";
  position: absolute;
  background-color: #ed3337;
  width: 122px;
  border-radius: 15px;
  height: 3px;
  bottom: 13px;
  left: 0;
}

.product-slider {
  padding: 0 0 50px 0;
  position: relative;

  .slick-slide {
    padding: 10px !important;
  }

  .slick-arrow {
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: #fff;
    border: 1px solid #ed3337;
    color: #ed3337;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px !important;
    border-radius: 50%;
    text-align: center;
    transition: 0.3s ease-in-out;

    @include breakpoint("md-max") {
      font-size: 30px !important;
      height: 60px;
      width: 60px;
      line-height: 60px;
    }
  }

  .slick-arrow:hover {
    background-color: #ed3337;
    color: #fff;
  }

  .slick-arrow-left {
    right: 50px;
    top: -5%;
    transform: translateY(-50%);
    z-index: 1;

    @include breakpoint("md-max") {
      right: 80px;
    }
  }

  .slick-arrow-right {
    right: 0;
    top: -5%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .product-card {
    text-align: center;
    position: relative;
    border-radius: 10px;
    margin: 10px;
    min-height: 350px;
    background-color: #fff;
    transition: transform 1.2s;
    overflow: hidden;
    width: 50%;

    @include breakpoint("md-max") {
      width: auto;
      height: 500px;
    }

    .product-image {
      height: 250px;
      width: 100%;
      object-fit: fill;
      height: 250px;
      border-radius: 10px;
      transition: transform .3s;

      @include breakpoint("md-max") {
        height: 450px;
        object-fit: contain;
      }
    }

    .product-details {
      padding: 15px;
      text-align: start;
      border: 1px solid #ddd;
      border-top: none;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @include breakpoint("md-max") {
        padding: 15px 30px;
        display: none;
      }

      .productDescripption {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include breakpoint("md-max") {
          font-size: 18px;
          text-align: justify;
        }
      }

      .product-name {
        font-size: 16px;
        margin-bottom: 10px;
        color: #777777;

        @include breakpoint("md-max") {
          font-size: 20px;
          font-weight: 800;
        }
      }

      .product-price {
        padding-bottom: 20px;

        .original-price {
          text-decoration: line-through;
          color: #999;
          margin-right: 10px;
        }

        .price {
          font-size: 16px;
          font-weight: bold;
          position: relative;
          border-radius: 10px;
        }

        .seller-price {
          font-size: 16px;
          font-weight: bold;
          position: relative;
          border-radius: 10px;

        }

      }
    }
  }



  .slick-prev,
  .slick-next {
    z-index: 1;
  }
}

.special-offers-section {
  padding: 50px 0;

  .offer-card {
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    background-image: url("../../../assets/317334.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    transition: all 0.5s;

    &:hover .offer-image {
      transform: scale(1.2);
    }

    .offer-content {
      width: 70%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      padding-left: 20px;

      .offer-title {
        font-size: 63px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .offer-description {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .offer-link {
        cursor: pointer;
        font-size: 18px;
        color: #fff;
        text-decoration: none;
        border-bottom: 2px solid #fff;
      }
    }

    .offer-image {
      width: 50%;
      position: relative;
      right: 0px;
      transition: all 0.5s;
    }
  }
}

.policy-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 50px 0;
  text-align: center;

  @include breakpoint("md-max") {
    grid-template-columns: repeat(2, 1fr) !important;
  }


  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      h3 {
        @include breakpoint("md-max") {
          font-size: 25px;
        }
      }

      p {
        @include breakpoint("md-max") {
          font-size: 22px;
        }
      }
    }

    .icon-outer {
      background-color: #fba7a9;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      margin-bottom: 15px;

      @include breakpoint("md-max") {
        height: 80px;
        width: 80px;
      }

      .icon {
        transform: translate(10%, 10%);
        margin-bottom: 20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: #ed3337;
        font-size: 18px;
        line-height: 50px;
        color: #fff;
        
        @include breakpoint("md-max") {
          transform: translate(7%, 7%); 
          line-height: 70px;
          height: 70px;
          width: 70px;
          font-size: 25px !important;
        }
      }

      .icon:hover {
        background-color: #ed3337;
        color: #fff;
        transition: 0.3s ease-in-out;
      }

      .icon-outer:hover {
        background-color: #ed3337;
        color: #fff;
        transition: 0.3s ease-in-out;
      }

    }

    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
    }

    .description {
      font-size: 14px;
      color: #999;
    }
  }
}

.all-product-btn-outer {
  display: flex;
  justify-content: center;

  @include breakpoint("md-max") {
    font-size: 20px;
  }

  .all-product-btn {

    border: 0.5px solid red;
    padding: 7px 15px;
    font-weight: 800;
    border-radius: 10px;
    background-color: #ed3337;
    color: #e9e9e9;
    transition: all ease-in-out .3S;

    &:hover {
      color: black;
      background-color: transparent;
    }
  }

}

.testimonials-section {
  text-align: center;
  background-color: #ececec;
  margin: 30px 0;
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;

  .testimonial {
    padding: 40px;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;

    @include breakpoint("md-max") {
      max-width: 600px;
      padding: 40px 60px;
    }

    .cenetr-image {
      display: flex;
      justify-content: center;

      img {
        object-fit: cover;
        width: 60px;
        height: 60px;

        @include breakpoint("md-max") {
          width: 80px;
          height: 80px;
        }
      }
    }

    .testimonial-image {
      border-radius: 50%;
      width: 80px;
      direction: flex;
      justify-content: center;
      height: 80px;
      margin-bottom: 20px;
    }

    .testimonial-text {
      font-size: 13px;
      color: #3a3a3a;
      margin-bottom: 20px;

      @include breakpoint("md-max") {
        font-size: 18px;
      }
    }

    .testimonial-name {
      padding-top: 40px;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .testimonial-email {
      padding-bottom: 40px;
      font-size: 16px;
      color: #999;

      @include breakpoint("md-max") {
        font-size: 18px;
      }
    }
  }

  .testimonial-navigation {
    margin-top: 20px;
    /* Add styles for navigation dots or arrows */
  }
}