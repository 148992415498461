@import "../../styles/index.scss";

.product-outer {
    padding: 20px 40px;
    .admin-dashboard-content {
        .title {

            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-add-btn {
                padding: 10px 15px;
                border: 0px;
                background: linear-gradient(180deg, #1d63dc 0%, #14469d 100%);
                color: white;
                border-radius: 10px;
                font-weight: 900;
            }
        }
    }
}
.deleteBtn {
    color: white;
    padding: 10px 15px;
    border: 0;
    margin: 0px 5px;
    border-radius: 7px;
    background-color: red;
    font-weight: 600;
}
.editBtn {
    font-weight: 600;
    color: white;
    border: 0;
    margin: 0px 5px;
    border-radius: 7px;
    background-color: #1d63dc;
    padding: 10px 15px;
}
.viewBtn {
    font-weight: 600;
    color: white;
    border: 0;
    margin: 0px 5px;
    border-radius: 7px;
    background-color: #1d63dc;
    padding: 10px 15px;
}

.btn-success {
    border: 0;
    padding: 10px 15px;
    border-radius: 7px;
    margin: 0px 5px;
    background-color: rgb(0, 124, 0);
    color: white;
    font-weight: 600;

}



.image-gallery {
    padding: 10px;
    display: flex;
    gap: 8px;
  }

  .product-image {
    width: 80px; 
    height: 80px; 
    object-fit: cover; 
    border-radius: 4px; 
  }