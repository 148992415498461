@import "../../styles/index.scss";

.user-outer {
    padding: 20px 40px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .sc-fpSrms {
        font-size: 30px;
        font-weight: 600;
    }

}