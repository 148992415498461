@import "../../../styles/index.scss";

.contact-page-alignment {
  padding: 100px 0;
  background-color: #f5f5f5;

  @include breakpoint("md-max") {
    padding: 60px 0;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    @include breakpoint("md-max") {
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .grid-items {
      h2 {
        margin: 0 0 24px 0;
        font-size: 42px;
        color: #050505;
        font-weight: 700;

        @include breakpoint("md-max") {
          font-size: 32px;
          line-height: 44px;
        }
      }
      p {
        font-size: 16px;
        line-height: 30px;
        color: #777;
      }

      .two-col-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 0 0 20px 0;
        @include breakpoint("md-max") {
          grid-template-columns: 1fr;
        }
      }

      .spacer {
        padding: 0 0 20px 0;
      }

      .input {
        label {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          display: block;
          padding: 0 0 9px 0;
          color: #777;
        }

        input {
          width: 100%;
          height: 40px;
          padding: 0 14px;
          font-size: 16px;
          color: #222;
          font-weight: 600;
          border: 1px solid #e0e0e0;
          transition: 0.3s ease-in-out;
          border-radius: 6px;
          &:focus {
            outline: none;
            border: 1px solid #0263cc;
          }
        }
        span{
          font-size: 12px;
          color: red !important;
          padding-left: 10px;
        }

        textarea {
          width: 100%;
          resize: none;
          height: 120px;
          padding: 14px;
          font-size: 16px;
          color: #222;
          font-weight: 600;
          border: 1px solid #e0e0e0;
          transition: 0.3s ease-in-out;
          border-radius: 6px;
          &:focus {
            outline: none;
            border: 1px solid #0263cc;
          }
        }
      }
    }
  }
}
