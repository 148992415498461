@import "../../styles/index.scss";

footer {
    padding: 100px 0 0 0;

    @include breakpoint("md-max") {
        padding: 60px 0 0 0;
    }

    .footer-alignment {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
        padding: 0 0 60px 0;

        @include breakpoint("md-max") {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
            padding: 0 0 40px 0;
        }


        .social-icon-alignment {
            display: flex;
            align-items: center;
            gap: 20px;
            @include breakpoint("md-max") {
                gap: 30px;
            }

            i {
                color: #e01a33;
                cursor: pointer;
                @include breakpoint("md-max") {
                    font-size: 30px;
                }
            }
        }

        h2 {
            color: #e01a33;
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 20px 0;

            @include breakpoint("md-max") {
                text-align: start;
            }
        }
        
        a:last-child {
            padding: 0;
        }
        
        .icon-text {
            display: flex;
            align-items: center;
            padding: 0 0 20px 0;
            gap: 12px;
            
            i {
                color: #e01a33;
                @include breakpoint("md-max") {
                    font-size: 20px;
                }
            }
            .contectLink{
                @include breakpoint("md-max") {
                    font-size: 20px;
                }
            }
        }
        
        a {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #222;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            position: relative;
            padding: 0 0 20px 0;
        }
        
        .first-contnet {
            p {
                font-size: 14px;
                line-height: 28px;
                color: #777777;
                max-width: 350px;
                @include breakpoint("md-max") {
                    text-align: justify;
                }
            }

            .logo {
                display: flex;
                align-items: center;
                gap: 14px;
                padding: 0 0 30px 0;
                cursor: pointer;

                img {
                    cursor: pointer;
                    max-width: 140px;
                    @include breakpoint("md-max") {
                        max-width: 200px;
                    }
                }

                span {
                    color: #e01a33;
                    display: block;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                }
            }
        }
    }
}


.copy-right {
    padding: 20px 0;

    p {
        font-size: 16px;
        line-height: 26px;
        color: #777;
        text-align: center;
        font-weight: 600;

        @include breakpoint("md-max") {
            font-size: 12px;
        }

        a {
            color: #e01a33;
            cursor: pointer;
        }
    }
}