@import "../../styles/index.scss";

.contect-outer {
    padding: 20px 40px;

    .sc-fpSrms {
        font-size: 30px;
        font-weight: 600;
    }
}
.hZUxNm{
    font-weight: 900;
}