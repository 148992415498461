@import '../../../styles/index.scss';

.heading {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  .sort-data {
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      padding: 5px 10px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: #f8f8f8;
      color: #333;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border-color: #ed3237;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }

    input {
      padding: 5px 10px;
      font-size: 16px;
      border-radius: 5px 0 0 5px;
      border: 1px solid #ccc;
      border-right: none;
      background-color: #f8f8f8;
      color: #333;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border-color: #ed3237;
      }
    }

    button {
      padding: 5px 15px;
      font-size: 16px;
      border-radius: 0 5px 5px 0;
      border: 1px solid #ed3237;
      background-color: #ed3237;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #c51d2d;
        border-color: #c51d2d;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px 20px;
  margin-top: 20px;

  @include breakpoint('md-max') {
    gap: 80px 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  .product-card {
    overflow: hidden;
    position: relative;

    @include breakpoint('md-max') {
      grid-template-columns: repeat(1, 1fr);
    }

    .product-image {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      height: 300px;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        transition: transform 0.3s;
      }
    }

    .product-details {
      padding: 15px 0 0 0;

      .product-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .product-name {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif;
      }

      .product-description {
        font-size: 14px;
        margin-bottom: 20px;
        color: rgb(70, 70, 70);
      }

      .price {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .product-actions {
      display: flex;
      gap: 10px;
      padding: 0px 20px 20px 20px;

      .add-to-cart {
        cursor: pointer;
        font-size: 18px;
        color: #e01a33;
        background-color: transparent;
        border: none;
      }

      .detail {
        cursor: pointer;
        font-size: 18px;
        color: #e01a33;
        background-color: transparent;
        border: none;
      }

      .detail-btn {
        // border: 0.5px solid red;
        padding: 5px 10px;
        font-weight: 800;
        border-radius: 18px;
        transition: transform 0.5s, background-color 0.5s, color 0.5s;
        /* Added transition for background-color and color */
      }
    }

    .eye {
      position: absolute;
      top: 14px;
      right: -100px;
      color: #ed3237;
      padding: 2px 15px 2px 5px;
      opacity: 0;
      border-radius: 5px 0px 0px 5px;
      transition: all 0.7s;
      cursor: pointer;
    }

    .add-cart {
      position: absolute;
      top: 250px;
      width: 100%;
      background-color: #0800019c;
      text-align: center;
      padding: 5px;
      opacity: 0;
      border-radius: 0px 0px 10px 10px;
      cursor: pointer;
      transition: all 0.5s;
      overflow: hidden;

      a {
        font-weight: 700;
        color: aliceblue;
      }
    }

    &:hover .add-cart {
      top: 271px;
      opacity: 1;
    }

    &:hover .eye {
      right: 0px;
      opacity: 1;
    }
  }
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Ensures content is spaced properly
  height: 100%; // Make sure the card takes full height
  padding: 12px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .product-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1; // Allows details to take up remaining space
    // padding: 10px 0;
    cursor: pointer;
  }

  .product-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 10px 0;
    margin-top: auto; // Pushes it to the bottom

    .product-price-container {
      display: flex;
      flex-direction: column;
      gap: 5px; // Adjust spacing if needed
    }
  }

  .product-price {
    font-size: 16px;
    font-weight: 600;
    color: #ed3237;
    // color: rgba(237, 50, 56, 0.4);
    // margin-bottom: 10px;
  }
  .product-price-dealer {
    font-size: 16px;
    font-weight: 600;
    color: #2973B2;
    // margin-bottom: 10px;
  }

  .add-to-cart {
    background-color: #ed3237;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background-color: #c51d2d;
    }
  }
}
