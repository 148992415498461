@import url(https://fonts.googleapis.com/css?family=Work+Sans|Open+Sans:wght@400;500;600&display=swap);

:root {
  // Font family
  --heading-font-family: "Work Sans";
  --text-font-family: "Open Sans";
  
  --default-font-family: sans-serif;

  // main colors
  // --primary-color: #E6FFFE;
  --primary-color: #6edbd5;
  --secondary-color: #fea133;
  --primary-light-color: #e5fef2;
  --invert-primary-color: #010101;
  --invert-secondary-color: #253d4d;
  --button-color: #6edbd5;

  // child color
  --gray-text-color: #707070;
  --black-3: #343434;
  --black-5-light: #505050;
  --black-text-color: #000;
  --black-6: #060606;
  --gray-5: #535353;
  --gray-7: #7a7a7a;
  --black-200: #2b2b2b;
  --gray-8: #858585;
  --gray-6: #6a6a6a;
  --gray-9: #979797;
  --black-1: #171717;
  --black-4: #313030;
  --gray-4: #444444;
  --dark-gray: #4e4e4e;
  --dark-gray-300: #363636;
  --text-current: #6e6e6e;
  --text-white: #fff;
  --gray-3: #333;
  --gray-500: #5d5d5d;
  --gray-400: #484848;
  --black-5: #1c1c1c;
  --black-light-300: #202020;
  --black-200: #2d2d2d;

  // border-color
  --light-border-color: #d6d6d6;
  --gray-border-color: #eeeeee;
  --border-color: #e9e9e9;
  --border-color-2: #a5a5a5;
  --border-color-3: #f2f2f2;
  --border-color-4: #dbdbdb;
  --border-color-5: #c5c5c5;
  --border-color-6: #b5b5b5;
  --border-color-7: #d1d1d1;
  --border-color-8: #e6e6e6;
  --border-color-9: #ececec;
  // bg-color
  --bg-light: #f9f9f9;
  --bg-dark-black: #171717;
  --bg-light-300: #e3e3e3;
  --bg-danger: #da2327;
  --bg-white: #fff;
  --bg-black: #030303;
  --bg-light-400: #ebebeb;
  --bg-light-200: #e4e4e4;
  --bg-light-300: #f7f7f7;

  // icon color
  --black-icon: var(--black-text-color);
  --light-icon: #3b3b3b;
}

.blue-theme {
  --primary-color: #1b84c0;
  --secondary-color: #8a89b9;
  --primary-light-color: #e5fef2;
  --invert-primary-color: #3e1d64;
  --invert-secondary-color: #301c88;
}
