@import "../../styles/index.scss";


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    // cursor: url('../../assets/');

    .popup {
        width: 900px;
        padding: 20px;
        text-align: center;
        position: relative;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 3px 66px rgba(0, 0, 0, .161);
        border-radius: 10px;
        position: relative;


        .close-btn {
            position: relative;
            right: -412px;
            margin-bottom: 10px;
            font-weight: 900;
            background-color: #dfdfdfbb;
            color: black;
            border: 0px;
            border-radius: 10px;
            height: 40px;
            width: 40px;
        }

        .form {
            .inputfield {
                padding: 20px 0px;
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(1, 1fr);

                input , select {
                    padding: 15px;
                    border: 1px solid #bdbdbd;
                    border-radius: 10px;
                }


                p {
                    color: red;
                    text-align: start;
                    font-size: 12px;
                    padding-left: 10px;
                    // margin: 0;
                }

                label {
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    border: 1px solid #bdbdbd;
                    border-radius: 10px;
                    text-align: start;
                    font-size: 15px;

                    i {
                        font-size: 20px;
                        padding: 0px 10px;
                        color: #0b5eec;
                    }

                }

                form {
                    display: flex;
                    gap: 10px;
                    flex-direction: column;
                }
            }

            .add-button {
                display: flex;
                justify-content: end;

                button {
                    padding: 10px 15px;
                    border: 0px;
                    background: linear-gradient(180deg, #1d63dc 0%, #14469d 100%);
                    color: white;
                    border-radius: 10px;
                    font-weight: 900;
                }
            }
        }
    }
}