@import "../../styles/index.scss";

.main-content {
    text-align: start;
    padding: 10px;

    img {
        width: 200px;
        height: 200px;
        object-fit: cover;

    }

    .manufacturer {
        padding-left: 10px;
        color: rgb(37, 37, 37);
    }
}