body{
    font-family: "Mulish", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

    /* background: linear-gradient(135deg, #e01a33, #6f5e5e, #3f8fcd); */
}



* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p:last-child {
  margin: 0;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

button {
  font-family: "Open Sans", sans-serif;
}

input {
  font-family: "Open Sans", sans-serif;
}

.container {
  max-width: calc(1280px + 40px);
  padding: 0 20px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}