@import "../../../styles/index.scss";



.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .popup {
        width: 900px;
        padding: 20px;
        text-align: center;
        position: relative;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 3px 66px rgba(0, 0, 0, .161);
        border-radius: 10px;
        position: relative;

        .close-btn {
            position: relative;
            right: -412px;
            margin-bottom: 10px;
            font-weight: 900;
            background-color: #dfdfdfbb;
            color: black;
            border: 0px;
            border-radius: 10px;
            height: 40px;
            width: 40px;
        }

        .main-detail-grid {
            display: grid;
            grid-template-columns: 1fr 300px;
            gap: 30px;
            @include breakpoint('md-max') {
                grid-template-columns: repeat(1, 1fr);
                padding: 50px;
                font-size: 12px;
            }
            
            .sub-item:first-child {
                display: grid;
                grid-template-columns: 100px 1fr;
                gap: 20px;    
                
            }
            
            .border {
                
                
                .display-image {
                    object-fit: cover;
                    width: 100%;
                }
                
                .product-other-image {
                    height: 100px;
                    width: 100px;
                    object-fit: cover;
                }
            }
            
            .sub-item:last-child {
                text-align: start;
                
                
                h3 {
                    padding-bottom: 10px;
                    color: #000000;
                    text-align: justify;
                    @include breakpoint('md-max') {
                        font-size: 30px;
                    }
                }
                
                h4 {
                    padding-bottom: 10px;
                    font-weight: 600;
                    @include breakpoint('md-max') {
                        font-size: 25px;
                    }
                }
                
                p {
                    padding-bottom: 10px;
                    font-size: 12px;
                    text-align: justify;
                    @include breakpoint('md-max') {
                        font-size: 20px;
                    }
                }
                
                .buy-section {
                    padding: 20px 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include breakpoint('md-max') {
                        font-size: 20px;
                    }
                    
                    .quantity-outer {
                        border: 1px solid #ccc;
                        display: inline-block;
                        
                        button {
                            height: 30px;
                            width: 30px;
                            border: 0px;
                            background-color: #ccc;
                            transition: all .3s;
                            
                            @include breakpoint('md-max') {
                                font-size: 20px;
                                padding: 10px 15px;
                                height: auto;
                                width: auto;
                            }
                            &:hover {
                                background-color: #ed3237;
                                color: #fff;
                            }
                        }
                        
                        span {
                            font-weight: 700;
                            width: 30px;
                            display: inline-block;
                            text-align: center;
                        }
                    }
                    
                    .buy-btn {
                        background-color: #ed3237;
                        color: white;
                        border: 0;
                        padding: 5px 10px;
                        border-radius: 7px;
                    }
                }
                
                .services {
                    margin-top: 20px;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    padding: 10px 0px;
                    
                    .services-inner {
                        display: grid;
                        grid-template-columns: 100px 1fr;
                        
                        .service-icon {
                            text-align: center;
                            font-size: 20px;
                            padding: 10px 0px;
                            color: #353535;
                            @include breakpoint('md-max') {
                                font-size: 30px;
                            }
                        }
                        
                        div {
                            @include breakpoint('md-max') {
                                font-size: 30px;
                            }
                            span {
                                font-size: 11px;
                                display: inline-block;
                                @include breakpoint('md-max') {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }


            }
        }
    }

    .active {
        border: 2px solid #ed3237;
    }
}