@import "../../../styles/index.scss";



.who-we-are-banner {
    background-image: url('../../../assets/light4.webp');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 700px;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;

    @include breakpoint("md-max") {
        height: auto;
        padding: 60px 0;
    }


    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0,0,0,0.7);
        content: '';
    }

    .w-full {
        width: 100%;
        position: relative;
        p {
            font-size: 16px;
            line-height: 26px;
            color: #fff;
            opacity: 0.8;
            margin: 0 0 24px 0;
            // max-width: 650px;
            text-align: start;
            @include breakpoint("md-max") {
                font-size: 14px;
            }
        }

        h1 {
            font-size: 51px;
            color: #fff;
            margin: 0 0 24px 0;
            max-width: 650px;
            position: relative;
            @include breakpoint("md-max") {
                font-size: 32px;
                line-height: 45px;
            }
        }
    }
}
.invision-story-alignment {
    padding: 100px 0;
    @include breakpoint("md-max") {
        padding: 60px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 120px;
        @include breakpoint("md-max") {
            grid-template-columns: 1fr;
            gap: 40px;
        } 
        .grid-items {
            h2 {
                margin: 0 0 24px 0;
                font-size: 42px;
                color: #050505;
                font-weight: 700;
                @include breakpoint("md-max") {
                    font-size: 32px;
                    line-height: 44px;
                }
            }
            p {
                font-size: 16px;
                line-height: 26px;
                color: #777;
                margin: 0 0 20px 0;

                @include breakpoint("md-max") {
                    font-size: 14px;
                }
            }
            .img {
                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    @include breakpoint("md-max") {
                        height: auto;
                    }
                }
            }
        }
    }
}
.who-weare-section-design-alignment {
    padding: 50px 0 100px 0;

    @include breakpoint("md-max") {
        padding: 0 0 60px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(1 , 1fr);
        gap: 100px;

        @include breakpoint("md-max") {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        .grid-items {
            h2 {
                margin: 0 0 20px 0;
                font-size: 32px;
                color: #ed3337;
                font-weight: 700;

                @include breakpoint("md-max") {
                    font-size: 24px;
                }
            }
            p {
                font-size: 16px;
                line-height: 28px;
                color: #777;
                margin: 0 0 20px 0;
                @include breakpoint("md-max") {
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
}