@import "../../styles/index.scss";


.auth-container {
  display: flex;
  height: 100vh;

  @include breakpoint("md-max") {
    display: block;
  }
}

.auth-left,
.auth-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.auth-left {
  background: linear-gradient(135deg, #e01a33, #6f5e5e, #3f8fcd);
  color: #ffffff;
  text-align: center;

  @include breakpoint("md-max") {
    height: 25%;
  }

  .logo {
    max-width: 150px;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
  }
}

.auth-right {
  background: #ffffff;
  color: #000000;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;

    @include breakpoint("md-max") {
      font-size: 4rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 400px;

    @include breakpoint("md-max") {
      margin: 0;
      font-size: 30px;
      max-width: 90%;
    }

    input {
      padding: 1rem;
      margin: 0.5rem 0;
      border: 1px solid #ccc;
      border-radius: 4px;

      @include breakpoint("md-max") {
        font-size: 2rem;
      }

    }

    .errorMsg {
      color: red;
      width: 100%;
      text-align: start;
      padding-left: 10px;
      font-size: 13px;

      @include breakpoint("md-max") {
        font-size: 25px;
      }
    }

    .dropdown-select {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      padding: 1rem;
      margin: 0.5rem 0;
      border: 1px solid #ccc;
      border-radius: 4px;

      @include breakpoint("md-max") {
        font-size: 2rem;
      }

    }



    label {
      padding: 1rem;
      margin: 0.5rem 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-align: start;

      i {
        padding: 0px 10px;
        // font-size: 15px;
      }
    }

    button {
      padding: 1rem;
      background-color: #e01a33;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
      margin-top: 1rem;

      @include breakpoint("md-max") {
        font-size: 2rem;
      }
    }
  }

  .social-login {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.2rem;
      margin: 0 0.5rem;
    }

    .google {
      color: #db4437;
    }

    .facebook {
      color: #4267b2;
    }

    .apple {
      color: #000000;
    }
  }

  p {
    margin-top: 1rem;

    @include breakpoint("md-max") {
      font-size: 2rem;
    }

    a {
      color: #e01a33;
      text-decoration: none;
    }
  }
}

.password-container {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;




  .password-toggle {
    position: absolute !important;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    background-color: none;
    border: none;
    cursor: pointer !important;
    font-size: 1.2rem;
    color: #000;
  }
}