@import "../../styles/index.scss";


.admin-dashboard-content{
  .card-outer{
    padding: 20PX;
    text-align: center;
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4 , 1fr);
    @include breakpoint("md-max") {
      grid-template-columns: repeat(2 , 1fr);
    }
    .card{
      padding: 30px;
      border-radius: 10px;
      background-color: rgb(240, 240, 240);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: column;
      
    }
  
  }
}