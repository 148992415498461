@import "../../styles/index.scss";

.layoutCustomSection {
    display: flex;

    .layoutCustomLeft {
        width: 300px;
        transition: 0.4s ease-in-out;

        @include breakpoint(md-max) {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
        }
    }

    .layoutCustomLefts {
        width: 0px;
        overflow: hidden;
        transition: 0.4s ease-in-out;
    }

    .layoutCustomRight {
        width: calc(100% - 300px);
        height: 100vh;
        overflow-y: auto;
        transition: 0.4s ease-in-out;
        
        @include breakpoint(md-max) {
            width: 100%;
        }
    }

    .layoutCustomRights {
        width: 100%;
        transition: 0.4s ease-in-out;
    }
}


.headerSection {
    width: 100%;
    background: linear-gradient(180deg, #1d63dc 0%, #14469d 100%);
   display: flex;
   justify-content: space-between;
    padding: 12px 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;

    .headerLeft {
        display: flex;
        align-items: center;
        gap: 13px;

        .headerSearch {
            width: 348px;
            display: flex;
            gap: 14px;
            background: #f5f5f5;
            border-radius: 50px;
            padding: 14px 20px;
            @include breakpoint("md-max") {
                width: 200px;
            }
            @include breakpoint("sm-max") {
                width: auto;
            }
            img {
                width: 14px;
                height: 14px;
                display: block;
                cursor: pointer;
            }
            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                font-size: 16px;
                color: #000;
                &::placeholder {
                    color: #000;
                }

                &:focus {
                    outline: none;
                }
                @include breakpoint("sm-max") {
                    display: none;
                }
            }
        }

        .toggleBtn {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            border: 1px solid #ccc;
            align-items: center;
          display: flex;
          justify-content: center;
            cursor: pointer;
            svg {
             
               stroke: #fff;
            }
        }
    }

    
}


.sidebarSection {
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #1d63dc 0%, #14469d 100%);
    padding: 35px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    position: relative;

    .sidebarTop {
        position: relative;
        z-index: 9;

        .sidebarFlexAlignment {
            display: flex;
            justify-content: space-between;
        }
        .sidebarLogo {
            cursor: pointer;
            width: 180px;
            @include breakpoint("md-max") {
                width: 140px;
            }
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .closeIcon {
            display: none;
            @include breakpoint("md-max") {
                display: block;
                img {
                    width: 24px;
                    height: 24px;
                    display: block;
                }
            }
        }
        .sidebarMenu {
            padding: 20px 0 0 0;

            .menuList {
                margin: 0 0 8px 0;
                transition: 0.4s ease-in-out;

                a {
                    padding: 12px 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    border-radius: 32px;

                    p {
                        font-size: 16px;
                        color:#fff;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        display: block;
                    }
                }

                &:hover {
                    a {
                        background: rgba(255, 255, 255, 0.1);
                    }
                }

                &.activeMenu {
                    a {
                        background: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }
    }

    .sidebarBottom {
        max-width: 243px;
        margin: 20px 0 0 0;
        position: relative;
        z-index: 9;

        p {
            font-size: 16px;
            color: #fff;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        background-repeat: no-repeat;
        background-size: cover;
    }

    &.smallSidebar {
        padding: 35px 8px;

        .sidebarTop {
            .sidebarLogo {
                width: 60px;
            }

            .sidebarMenu {
                .menuList {
                    p {
                        display: none;
                    }
                }
            }
        }

        .sidebarBottom {
            display: none;
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background: #333;
    color: white;
    transition: left 0.3s ease;
    padding: 1rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.open {
    left: 0;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}
